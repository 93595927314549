import React from "react";
import cx from "classnames";
import style from "./GridCard.module.scss";
import Arrow from "../../../assets/next.svg";

const GridCard = ({icon = "graph", iconSize, title, children, to, green = false}) => {
  const Card = (
    <>
      {icon && style[icon] && <div className={cx(style.icon, style[iconSize], style[icon])} />}
      <div className={style.bg} />
      <div className={style.title}>{title}</div>
      <div className={style.content}>{children}</div>
      {to && <Arrow className={style.arrow} />}
    </>
  );

  return React.createElement(to ? "a" : "div", {className: cx(style.card, {[style.green]: green}), href: to}, Card);
};

export default GridCard;
