import React from "react";
import cx from "classnames";
import style from "./Clients.module.scss";
import Section from "../../atoms/Section/Section";
import {Container, Row} from "../../atoms/Containers/Conainers";
import GreenTitle from "../../atoms/GreenTitle/GreenTitle";
import Text from "../../atoms/Text/Text";
import Space from "../../elements/Space/Space";
import Shape from "../../elements/Shape/Shape";

const Clients = () => (
  <Section>
    <Container>
      <Row>
        {/*         <div className='col-12 text-center mb-3 mb-md-0'>
          <h2 className='m-0'>Alcuni Clienti</h2>
        </div> */}
        <div className='col-12 text-center mt-3'>
          <GreenTitle caps>Alcuni Clienti</GreenTitle>
        </div>
      </Row>
      <Row>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/tim.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/europcar.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/mediolanum.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/vodafone.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/fastweb.png' className={cx(style.logo)} />
        </div>
      </Row>
      <Row>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/enel.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/windtre.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/sistema.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/sigla.png' className={cx(style.logo)} />
        </div>
        <div className={cx("col-6 col-md", style.logoWrapper)}>
          <img src='/clients/baro.png' className={cx(style.logo)} />
        </div>
      </Row>
    </Container>
  </Section>
);

export default Clients;
