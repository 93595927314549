import React, { useState } from "react";
import cx from "classnames";
import style from "./FormSlider.module.scss";
import Space from "../../elements/Space/Space";
import GreenTitle from "../../atoms/GreenTitle/GreenTitle";
import { Stickyroll } from "@stickyroll/stickyroll";
import { ContainerRow, ContainerFullCol } from "../../atoms/Containers/Conainers";
import Section from "../../atoms/Section/Section";

const FormSlider = ({ children, title = "Asset proprietari" }) => {
  const [current, setCurrent] = useState(0);
  const slides = React.Children.map(children, child => child);
  const labels = React.Children.map(children, child => child.props.label);
  const className = progress => `custom-trigger custom-trigger--${Math.round(progress * 3)}`;

  return (
    <>
      <Section className='d-none d-lg-block'>
        <ContainerRow>
          <div className='col-6'>
            <Stickyroll pages={slides} factor={0.4} throttle={250}>
              {({ pageIndex, progress }) => {
                setCurrent(pageIndex);
                return slides.map((s, i) => {
                  return <span className={pageIndex === i ? "sticky sticky-d" : `sticky sticky-none ${className(progress)}`}>{s}</span>;
                });
              }}
            </Stickyroll>
          </div>
          <div className='col-6'>
            <div className={cx(style.column, style.left)}>
              <GreenTitle caps>Come funziona</GreenTitle>
              <h3 className='mt-1 mb-4'>{title}</h3>
              {labels.map((label, index) => (
                <Label active={index === current} mobile={false}>
                  {label}
                </Label>
              ))}
            </div>
          </div>
        </ContainerRow>
      </Section>
      <Section className='relative d-lg-none'>
        <Space mobile={10} table={15} desktop={20} />
        <ContainerFullCol>
          {slides.map((slide, index) => (
            <div>
              <Label active={true} mobile={true}>
                {labels[index]}
              </Label>
              {slide}
            </div>
          ))}
        </ContainerFullCol>
        <Space mobile={10} table={15} desktop={20} />
      </Section>
    </>
  );
};

export const FormSlide = ({ children, image }) => (
  <div className={style.column}>
    {image && <div className={style.image} style={{ backgroundImage: `url(${image})` }} />}
    {children}
  </div>
);

export const Label = ({ children, active, mobile }) => (
  <div className={cx(style.slideLabel, { [style.slideLabelActive]: active }, { [style.labelIconMobile]: mobile })}>
    <div className={cx(style.labelIcon, { [style.labelIconActive]: active }, { [style.labelIconMobile]: mobile })} />
    {children}
  </div>
);

export default FormSlider;
